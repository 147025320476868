var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',_vm._g(_vm._b({staticClass:"platform-selector",class:{ 'contain-select-width': !_vm.fullWidth && !_vm.onlyIcons, 'icons-only': _vm.onlyIcons },attrs:{"hide-details":"","solo":_vm.solo && !_vm.outlined,"flat":!_vm.outlined,"disabled":_vm.disabled,"outlined":_vm.outlined,"dense":_vm.small,"value":_vm.value,"items":_vm.items,"label":_vm.label,"multiple":_vm.allowMultiple,"placeholder":_vm.allowMultiple ? 'Select platforms' : null,"background-color":_vm.dark ? 'secondary' : null},on:{"input":function (v) { return _vm.$emit('input', v); },"change":function (e) { return _vm.$emit('change', e); }},scopedSlots:_vm._u([(_vm.allowMultiple)?{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"mousedown":function($event){$event.preventDefault();},"click":_vm.handleSelectAll}},[_c('v-list-item-action',{staticClass:"mr-3"},[_c('v-icon',{attrs:{"color":"text"}},[_vm._v(" "+_vm._s(_vm.value.length === _vm.items.length ? 'close' : 'check_box_outline_blank')+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Select All ")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true}:null,{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"ripple":""}},'v-list-item',attrs,false),on),[(item.image)?_c('v-list-item-action',{staticClass:"ml-auto mr-auto"},[_c('v-img',{attrs:{"height":_vm.small ? 24 : 36,"width":_vm.small ? 24 : 36,"src":item.image}})],1):_vm._e(),(_vm.fullWidth || _vm.isDesktopDevice || item.text === 'All')?[_c('v-list-item-content',{staticClass:"pl-3"},[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.text)+" ")])],1)]:_vm._e()],2)]}},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.allowMultiple)?_c('div',[(index === 0)?_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"multi-platform-logos d-flex"},_vm._l((_vm.value),function(platform){return _c('img',{key:platform,class:{ 'mr-1': _vm.value.length <= 3, 'mr-2': _vm.value.length > 3 },attrs:{"height":_vm.small ? 18 : 30,"width":_vm.small ? 18 : 30,"src":_vm.platformIconMap[platform]}})}),0),(_vm.fullWidth || _vm.isDesktopDevice)?_c('div',{staticClass:"pl-3 white-space-nowrap"},[(_vm.value.length === 1)?_c('span',[_vm._v(" "+_vm._s(_vm.capitalizeString(_vm.value[0]))+" ")]):(_vm.value.length <= 3)?_c('span',[_vm._v(" "+_vm._s(_vm.value.length)+" platforms ")]):_vm._e()]):_vm._e()]):_vm._e()]):_c('div',{staticClass:"d-flex align-center"},[(item.image)?_c('v-img',{class:{ 'grayscale': _vm.disabled },attrs:{"height":_vm.small ? 18 : 30,"width":_vm.small ? 18 : 30,"src":item.image}}):_vm._e(),(!_vm.onlyIcons)?[(_vm.fullWidth || _vm.isDesktopDevice || item.text === 'All')?_c('span',{class:{ 'ml-2': _vm.small, 'ml-3': !_vm.small }},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e()]:_vm._e()],2)]}}],null,true)},'v-select',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }