<template>
  <v-select
    hide-details
    :solo="solo && !outlined"
    :flat="!outlined"
    :disabled="disabled"
    :outlined="outlined"
    :dense="small"
    :value="value"
    :items="items"
    :label="label"
    :multiple="allowMultiple"
    :placeholder="allowMultiple ? 'Select platforms' : null"
    @input="(v) => $emit('input', v)"
    @change="(e) => $emit('change', e)"
    class="platform-selector"
    :class="{ 'contain-select-width': !fullWidth && !onlyIcons, 'icons-only': onlyIcons }"
    :background-color="dark ? 'secondary' : null"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template
      v-if="allowMultiple"
      v-slot:prepend-item
    >
      <v-list-item
        ripple
        @mousedown.prevent
        @click="handleSelectAll"
      >
        <v-list-item-action class="mr-3">
          <v-icon color="text">
            {{ value.length === items.length ? 'close' : 'check_box_outline_blank' }}
          </v-icon>
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title>
            Select All
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>

    <template v-slot:item="{ item, on, attrs }">
      <v-list-item ripple v-bind="attrs" v-on="on">
        <v-list-item-action
          v-if="item.image"
          class="ml-auto mr-auto"
        >
          <v-img
            :height="small ? 24 : 36"
            :width="small ? 24 : 36"
            :src="item.image"
          />
        </v-list-item-action>

        <template v-if="fullWidth || isDesktopDevice || item.text === 'All'">
          <v-list-item-content class="pl-3">
            <v-list-item-title>
              {{ item.text }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
      </v-list-item>
    </template>

    <template v-slot:selection="{ item, index }">
      <div v-if="allowMultiple">
        <!-- If it's the first index -->
        <div
          v-if="index === 0"
          class="d-flex align-center"
        >
          <!-- Show the icons -->
          <div class="multi-platform-logos d-flex">
            <img
              v-for="platform in value"
              :key="platform"
              :height="small ? 18 : 30"
              :width="small ? 18 : 30"
              :src="platformIconMap[platform]"
              :class="{ 'mr-1': value.length <= 3, 'mr-2': value.length > 3 }"
            />
          </div>

          <div
            v-if="fullWidth || isDesktopDevice"
            class="pl-3 white-space-nowrap"
          >
            <span v-if="value.length === 1">
              {{ capitalizeString(value[0]) }}
            </span>

            <span v-else-if="value.length <= 3">
              {{ value.length }} platforms
            </span>
          </div>
        </div>
      </div>

      <div
        v-else
        class="d-flex align-center"
      >
        <v-img
          v-if="item.image"
          :height="small ? 18 : 30"
          :width="small ? 18 : 30"
          :class="{ 'grayscale': disabled }"
          :src="item.image"
        />

        <template v-if="!onlyIcons">
          <span
            v-if="fullWidth || isDesktopDevice || item.text === 'All'"
            :class="{ 'ml-2': small, 'ml-3': !small }"
          >
            {{ item.text }}
          </span>
        </template>
      </div>
    </template>
  </v-select>
</template>

<script>
// Export the SFC
export default {
  // Name of the component
  name: "PlatformSelector",

  // Accept incoming data from parent
  props: {
    value: {
      type: [String, Array],
      required: false,
      default: null
    },

    dark: {
      type: Boolean,
      required: false,
      default: false
    },

    small: {
      type: Boolean,
      required: false,
      default: false
    },

    solo: {
      type: Boolean,
      required: false,
      default: true
    },

    outlined: {
      type: Boolean,
      required: false,
      default: false
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false
    },

    label: {
      type: String,
      required: false,
      default: null
    },

    showAll: {
      type: Boolean,
      required: false,
      default: false
    },

    allText: {
      type: String,
      required: false,
      default: "All"
    },

    showInstagram: {
      type: Boolean,
      required: false,
      default: true
    },

    showYoutube: {
      type: Boolean,
      required: false,
      default: true
    },

    showTiktok: {
      type: Boolean,
      required: false,
      default: true
    },

    showSnapchat: {
      type: Boolean,
      required: false,
      default: false
    },

    showLinkedin: {
      type: Boolean,
      required: false,
      default: false
    },

    fullWidth: {
      type: Boolean,
      required: false,
      default: false
    },

    allowMultiple: {
      type: Boolean,
      required: false,
      default: false
    },

    onlyIcons: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  // Define computable properties
  computed: {
    /**
     * Get all the select input option values
     *
     * @returns {Array}
     */
    items() {
      return [
        {
          isVisible: this.showAll && !this.allowMultiple,
          text: this.allText,
          image: null,
          value: null
        },
        {
          isVisible: this.showInstagram,
          text: "Instagram",
          image: platformIconMap.instagram,
          value: "instagram"
        },
        {
          isVisible: this.showYoutube,
          text: "Youtube",
          image: platformIconMap.youtube,
          value: "youtube"
        },
        {
          isVisible: this.showTiktok,
          text: "TikTok",
          image: platformIconMap.tiktok,
          value: "tiktok"
        },
        {
          isVisible: this.showSnapchat,
          text: "Snapchat",
          image: platformIconMap.snapchat,
          value: "snapchat"
        },
        {
          isVisible: this.showLinkedin,
          text: "LinkedIn",
          image: platformIconMap.linkedin,
          value: "linkedin"
        }
      ].filter((item) => item.isVisible)
    }
  },

  // Define local method functions
  methods: {
    /**
     * Handle the select all option
     *
     * @returns {void}
     */
    handleSelectAll() {
      // If all the items are selected
      if (this.value.length === this.items.length) {
        // Set the value to an empty array
        this.$emit("input", [])
      } else {
        // Set the value to all the items
        this.$emit("input", this.items.map((item) => item.value))
      }
    }
  },

  // As soon as the component is ready
  created() {
    // If the default value is empty, send the first item from list
    if (!this.value) {
      // Set the value
      this.$emit("input", this.items[0].value)
    }
  }
}
</script>

<style lang="stylus">
.platform-selector
  min-width 6em

  &.contain-select-width
    width 12em
    min-width 12em
    max-width 12em

    @media (max-width 960px)
      width 6em
      min-width 6em
      max-width 6em

  &.icons-only
    width 5em
    min-width 4em
    max-width 6em

  input
    display none !important
</style>

