<template>
  <platform-selector-block
    label="Platform"
    :show-all="showAll && overview.model.platforms.length > 1"
    :show-tiktok="overview.model.platforms.includes('tiktok')"
    :show-youtube="overview.model.platforms.includes('youtube')"
    :show-instagram="overview.model.platforms.includes('instagram')"
    :show-snapchat="overview.model.platforms.includes('snapchat')"
    :show-linkedin="overview.model.platforms.includes('linkedin')"
    :value="selectedPlatform"
    @input="updateSelectedPlatform"
    v-bind="$attrs"
  />
</template>

<script>
// Import children components
const PlatformSelectorBlock = () => import(/* webpackChunkName: "platform-selector" */ "@/blocks/common/selectors/PlatformSelector.vue")

// Export the SFC
export default {
  // Name of the component
  name: "PlatformSelector",

  // Register children components
  components: {
    PlatformSelectorBlock,
  },

  // Accept incoming data from parent
  props: {
    module: {
      type: String,
      required: true,
    },

    overview: {
      type: Object,
      required: true
    },

    showAll: {
      type: Boolean,
      default: true
    }
  },

  // Define readonly data variables
  computed: {
    /**
     * Get the currently selected platform
     *
     * @returns {String|Null}
     */
    selectedPlatform() {
      return this.$store.getters[`${this.module}/selectedPlatformById`](this.overview.model.id)
    }
  },

  // Define local method functions
  methods: {
    /**
     * Update the selected platform's value in store
     *
     * @param {String|Null} value
     * @returns {void}
     */
    updateSelectedPlatform(value) {
      // If the value isn't different
      if (value === this.selectedPlatform) {
        return
      }

      // Update the selected platform in store
      this.$store.dispatch(`${this.module}/updateSelectedPlatform`, { id: this.overview.model.id, value })
      // Also emit this
      this.$emit("input", value)
    }
  }
}
</script>
